import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import styles from "./qui-sommes-nous.module.scss"
import Seo from "../components/seo"

const QuiSommesNous = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/qui-sommes-nous/aboutUs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="qui sommes-nous"
        description="présentation de l'équipe Wistibike, notre but et nos valeurs"
      />
      <main className={styles.mainContainer}>
        <article className={styles.card}>
          <h1>Qui sommes-nous ?</h1>
          <section className={styles.imageContainer}>
            <Img
              className={styles.image}
              fluid={data.file.childImageSharp.fluid}
              alt="plein milieu d'une forêt"
            />
          </section>
        </article>
        <section className={styles.text}>
          <p>
            Nous sommes une start-up savoyarde située à Ambilly, à cheval entre
            la France et la Suisse. Nous formons une belle équipe de salariés
            passionnés de vélos et de nature. <br />
            <br />
            Nous avons crée cette entreprise pour répondre à la demande
            croissante de la population en vélos éléctriques. <br />
            <br />
            Notre équipe a selectionné une gamme de produits qui présentent le
            meilleur rapport qualité/prix.
          </p>
          <span>L'équipe Wistibike</span>
        </section>
      </main>
    </Layout>
  )
}

export default QuiSommesNous
